//入口文件
// import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
    // import VueResource from 'vue-resource'
    // Vue.use(VueResource)

import axios from 'axios'
import Qs from 'qs'
//QS是axios库中带的，不需要我们再npm安装一个

Vue.prototype.axios = axios;
Vue.prototype.qs = Qs;

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


import VueMeta from 'vue-meta'
Vue.use(VueMeta);

import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI);



import Vue from '../node_modules/vue/dist/vue.common.js'
import router from './router.js'
import App from './App.vue' //首页


import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)



var vm = new Vue({
    el: '#app',
    render: c => c(App),
    router
})


NProgress.configure({
    easing: 'ease', // 动画方式    
    speed: 500, // 递增进度条的速度    
    showSpinner: false, // 是否显示加载ico    
    trickleSpeed: 200, // 自动递增间隔    
    minimum: 0.3 // 初始化时的最小百分比
})


router.beforeEach((to, from, next) => {
    // 每次切换页面时，调用进度条
    NProgress.start();

    // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
    next();
})


// router.afterEach((to, from, next) => {

// })