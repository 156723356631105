import Vue from 'vue';
import CryptoJS from 'crypto-js';
export default { //加密
    e(word, keyStr) {
        keyStr = keyStr ? keyStr : 'abcdefgabcdefg12';
        var key = CryptoJS.enc.Utf8.parse(keyStr); //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
        var srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString();
    },
    //解密
    d(word) {
        var key = CryptoJS.enc.Utf8.parse(word.data.substring(0, 16)); //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
        var decrypt = CryptoJS.AES.decrypt(word.data.substring(16, word.data.length), key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    },
    de(word) {
        word = CryptoJS.enc.Base64.parse(word);
        word = word.toString(CryptoJS.enc.Utf8)
        var key = CryptoJS.enc.Utf8.parse(word.substring(0, 16));
        var decrypt = CryptoJS.AES.decrypt(word.substring(16, word.length), key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypt).toString());
    },
    c(word) {
        var parsedWordArray = CryptoJS.enc.Base64.parse(word);
        word = parsedWordArray.toString(CryptoJS.enc.Utf8);
        var key = CryptoJS.enc.Utf8.parse(word.substring(0, 16)); //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
        var decrypt = CryptoJS.AES.decrypt(word.substring(16, word.length), key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    },
    bO(word) {
        var keyStr = Math.random().toString(36).substr(2, 10) + Math.random().toString(36).substr(2, 6);
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        var rawStr = keyStr + encrypted.toString();
        var wordArray = CryptoJS.enc.Utf8.parse(rawStr);
        var base64 = CryptoJS.enc.Base64.stringify(wordArray);
        return base64;
    },
    bE(word) {
        return new Promise((resolve, reject) => {
            var keyStr = Math.random().toString(36).substr(2, 10) + Math.random().toString(36).substr(2, 6);
            var key = CryptoJS.enc.Utf8.parse(keyStr);
            var srcs = CryptoJS.enc.Utf8.parse(word);
            var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
            var rawStr = keyStr + encrypted.toString();
            var wordArray = CryptoJS.enc.Utf8.parse(rawStr);
            var base64 = CryptoJS.enc.Base64.stringify(wordArray);
            resolve(base64)
        })
    },
    r(randomFlag, min, max) {
        var str = "",
            pos = '',
            range = min,
            arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        // 随机产生
        if (randomFlag) {
            range = Math.round(Math.random() * (max - min)) + min;
        }
        for (var i = 0; i < range; i++) {
            pos = Math.round(Math.random() * (arr.length - 1));
            str += arr[pos];
        }
        return str;
    },
    b(word) {
        return new Promise((resolve, reject) => {
            var keyStr = this.r(false, 16, 16);
            var key = CryptoJS.enc.Utf8.parse(keyStr);
            var srcs = CryptoJS.enc.Utf8.parse(word);
            var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
            var rawStr = keyStr + encrypted.toString();
            var wordArray = CryptoJS.enc.Utf8.parse(rawStr);
            var base64 = CryptoJS.enc.Base64.stringify(wordArray);
            resolve(base64)
        })
    },
    m(word) {
        return CryptoJS.MD5(word).toString();
    },
    s(word) {
        var wordArray = CryptoJS.enc.Utf8.parse(word);
        return CryptoJS.enc.Base64.stringify(wordArray);
    }
}