import VueRouter from 'vue-router'
import NProgress from 'nprogress'

// import mainBlog from './main/mainBlog.vue'
// import archive from './main/archive.vue' //加载更多
// import content from './main/content.vue' //内容


const error = resolve => require(['./main/error.vue'], resolve);

const login = resolve => require(['./main/login.vue'], resolve);
const home = resolve => require(['./main/home.vue'], resolve);
const appleDevloper = resolve => require(['./main/appleDeveloper.vue'], resolve);
const buy = resolve => require(['./main/buy.vue'], resolve);
const order = resolve => require(['./main/order.vue'], resolve);

var title = '折纸科技'

var router = new VueRouter({
    routes: [{
            name: 'home',
            path: '/',
            component: home,
            meta: {
                keepAlive: false,
                isBack: false,
                title: title
            }
        }, {
            name: 'login',
            path: '/login',
            component: login,
            meta: {
                keepAlive: false,
                isBack: false,
                title: '登录' + ' - ' + title
            }
        }, {
            name: 'appleDeveloper',
            path: '/apple-developer',
            component: appleDevloper,
            meta: {
                keepAlive: false,
                isBack: false,
                title: 'Apple Developer Program' + ' - ' + title
            }
        }, {
            name: 'buy',
            path: '/buy/:id',
            component: buy,
            meta: {
                keepAlive: false,
                isBack: false,
                title: '下单' + ' - ' + title
            }
        }, {
            name: 'buy',
            path: '/order',
            component: order,
            meta: {
                keepAlive: false,
                isBack: false,
                title: '订单状态' + ' - ' + title
            }
        },
        {
            name: 'error',
            path: '/error/:code',
            component: error,
            meta: {
                keepAlive: false,
                isBack: false,
                title: '页面加载出现问题' + ' - ' + title
            }
        },
        {
            name: 'notFound',
            path: '*', // 页面不存在的情况下会跳到404页面
            redirect: '/error/402',
            hidden: true
        }
    ]
})


router.afterEach(() => {
    // 在即将进入新的页面组件前，关闭掉进度条
    NProgress.done()
})




export default router