var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"headerContainer"},[_c('div',{staticClass:"header",style:(_vm.cancelFixed ? 'position:static' : '')},[_c('div',{staticClass:"headerInside",attrs:{"showmenu":_vm.showMenu,"menu":_vm.menu}},[_c('div',{staticClass:"logo",on:{"click":function($event){return _vm.openPage('/')}}}),_c('div',{staticClass:"headerLeft"},_vm._l((_vm.header),function(item,index){return _c('div',{key:index,staticClass:"headerItemContainer",style:(`width:${100 / _vm.header.length}%;font-weight:${
              index == _vm.clickIndex && false ? 'bold' : 'normal'
            };transition-delay: ${
              !_vm.showMenu ? 80 * (_vm.header.length - index - 1) : 80 * index
            }ms`),on:{"click":function($event){return _vm.openPage(item.link, index)}}},[_c('div',{staticClass:"headerText"},[_vm._v(_vm._s(item.title))])])}),0),_c('div',{staticClass:"headerRight"},[(false)?_c('div',{staticClass:"headerAccount",style:(`background-color:${_vm.avatarColor};${
              _vm.avatarUrl ? 'background-image: url(' + _vm.avatarUrl + ')' : ''
            };`),on:{"click":_vm.account}}):_vm._e(),_c('div',{staticClass:"topMenu",on:{"click":function($event){return _vm.topMenu()}}},[_c('div',{staticClass:"topMenuLine"}),_c('div',{staticClass:"topMenuLine"})])])])])]),(false)?_c('div',{staticClass:"topMessages",style:(_vm.cancelFixed ? 'padding-top:0px' : ''),on:{"click":function($event){return _vm.jumpToMessage()}}},[_vm._m(0)]):_vm._e(),_c('div',{style:('padding-top:' +
      (_vm.cancelFixed ? 0 : 'var(--headerHeight);') +
      (_vm.disabledScroll ? 'overflow:hidden;height:100vh' : ''))},[_c('keep-alive',[(this.$route.meta.keepAlive)?_c('router-view',{directives:[{name:"wechat-title",rawName:"v-wechat-title",value:(_vm.$route.meta.title),expression:"$route.meta.title"}]}):_vm._e()],1),(!this.$route.meta.keepAlive)?_c('router-view',{directives:[{name:"wechat-title",rawName:"v-wechat-title",value:(_vm.$route.meta.title),expression:"$route.meta.title"}]}):_vm._e()],1),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footerInside"},[_vm._m(1),_c('div',{staticClass:"footerItem",staticStyle:{"unser-elect":"none","cursor":"pointer"},on:{"click":function($event){return _vm.connectSupport()}}},[_c('a',[_vm._v("联系我们")])]),_vm._m(2),_vm._m(3)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topMessagesInside"},[_vm._v(" 《致广大用户的一封信》 "),_c('div',{staticClass:"topMessagesJump"},[_vm._v(" 前往查看"),_c('i',{staticStyle:{"padding-left":"5px","font-size":"70%","vertical-align":"0px"}},[_vm._v("")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('a',{attrs:{"href":"https://api.zhezhi.tech/","target":"_blank"}},[_vm._v("Copyright © 2023 折纸科技")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("浙ICP备2022025157号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('a',{attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002016701","target":"_blank"}},[_vm._v("浙公网安备33011002016701号")])])
}]

export { render, staticRenderFns }